import Phaser from 'phaser'

import Marienkaefer from './scenes/Marienkaefer'

const config: Phaser.Types.Core.GameConfig = {
	type: Phaser.AUTO,
	width: 1600,
	height: 1600,
	scale: {
		// Fit to window
		mode: Phaser.Scale.FIT,
		// Center vertically and horizontally
		autoCenter: Phaser.Scale.CENTER_BOTH
	},
	// physics: {
	// 	default: 'arcade',
	// 	arcade: {
	// 		gravity: { y: 200 }
	// 	}
	// },
	scene: [Marienkaefer]
}

export default new Phaser.Game(config)