import Point from "./Point";
import Node from "./Node";
import { matrix } from 'mathjs';

export default class AStar {
  private stack = [];
  private visited = [];

  static findRoute(maze: string[][][], ladybugCoords: Point, targetCoords: Point) {
    // console.log(maze);
    // console.log(ladybug);
    // console.log(target);

    let start = new Point(ladybugCoords.x, ladybugCoords.y);
    let end = new Point(targetCoords.x, targetCoords.y);
    let path = this.findPath(maze, start, end);
    console.log(path);
    return path;
  }

  static findPath(maze: string[][][], start: Point, end: Point) {
    let stack: Array<Node> = [];
    let visited: Array<Node> = [];
    let expanded: Array<Node> = [];
    var startNode = new Node(start, 0, this.getDistance(start, end));
    var endNode = new Node(end, 0, 0);
    stack.push(startNode);
    visited.push(startNode);
    expanded.push(startNode);
    while (stack.length > 0) {
      stack.sort((a, b) => b.combinedCost - a.combinedCost);
      console.log('stack length', stack.length);
      stack.forEach(node => console.log(node));
      let current = stack.pop();
      console.log('current', current);
      if (current === undefined) {
        break;
      }
      visited.push(current);
      if (current.x === end.x && current.y === end.y) {
        return { path: this.buildPath(current), expanded: expanded };
      }
      let neighbors = this.getNeighbors(maze, current, end);
      for (let i = 0; i < neighbors.length; i++) {
        let neighbor = neighbors[i];
        expanded.push(neighbor);
        if (!visited.find(v => v.x == neighbor.x && v.y == neighbor.y)) {
          neighbor.parent = current;
          stack.push(neighbor);
        }
      }
    }
    return { path: [], expanded: expanded };
  }

  static getDistance(start: Point, end: Point) {
    return Math.sqrt(Math.pow(start.x - end.x, 2) + Math.pow(start.y - end.y, 2));
  }

  static getNeighbors(maze: string[][][], current: Node, end: Point) {
    let neighbors = [];
    let leftPoint = new Point(current.x - 1, current.y);
    var left = this.safeGetNeighbor(maze, leftPoint);
    let rightPoint = new Point(current.x + 1, current.y);
    var right = this.safeGetNeighbor(maze, rightPoint);
    let topPoint = new Point(current.x, current.y - 1);
    var top = this.safeGetNeighbor(maze, topPoint);
    let bottomPoint = new Point(current.x, current.y + 1);
    var bottom = this.safeGetNeighbor(maze, bottomPoint);
    const cost = current.cost + 1;
    if (left && !left.find(n => n == 'wall')) {
      neighbors.push(new Node(leftPoint, cost, this.getDistance(leftPoint, end)));
    }
    if (right && !right.find(n => n == 'wall')) {
      neighbors.push(new Node(rightPoint, cost, this.getDistance(rightPoint, end)));
    }
    if (top && !top.find(n => n == 'wall')) {
      neighbors.push(new Node(topPoint, cost, this.getDistance(topPoint, end)));
    }
    if (bottom && !bottom.find(n => n == 'wall')) {
      neighbors.push(new Node(bottomPoint, cost, this.getDistance(bottomPoint, end)));
    }
    // if (current.x > 0 && maze[current.y][current.x - 1] !== "wall") {
    //   console.log('left no wall');
    //   neighbors.push(new Node(current.x - 1, current.y));
    // }
    // if (current.x < maze[0].length - 1 && maze[current.y][current.x + 1] !== "wall") {
    //   console.log('right no wall');
    //   neighbors.push(new Node(current.x + 1, current.y));
    // }
    // if (current.y > 0 && maze[current.y - 1][current.x] !== "wall") {
    //   console.log('top no wall');
    //   neighbors.push(new Node(current.x, current.y - 1));
    // }
    // if (current.y < maze.length - 1 && maze[current.y + 1][current.x] !== "wall") {
    //   console.log('down no wall');
    //   neighbors.push(new Node(current.x, current.y + 1));
    // }
    return neighbors;
  }

  static safeGetNeighbor(maze: string[][][], point: Point) {
    if (point.y < maze.length && point.y >= 0 && point.x < maze[0].length && point.x >= 0) {
      return maze[point.y][point.x];
    }
    return null;
  }

  static buildPath(current: Node) {
    let path = [];
    while (current.parent) {
      path.push(current);
      current = current.parent;
    }
    return path;
  }
}