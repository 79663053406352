import Phaser from 'phaser';
import { Align, BaseScene } from 'phaser-utility';
import ladybug from '../assets/ladybug.svg';
import AStar from '../lib/AStar';
import MazeBuilder from '../lib/MazeBuilder';
import Point from '../lib/Point';
import Node from "../lib/Node";

enum ImageNames {
  Ladybug = 'ladybug',
}

export default class HelloWorldScene extends BaseScene {
  public blocks: Phaser.GameObjects.Group | undefined;
  maze!: MazeBuilder;
  ladybug: Phaser.GameObjects.Image | undefined;
  key: Phaser.GameObjects.Rectangle | undefined;
  ladybugCoords: Point | undefined;
  keyCoords: Point | undefined;

  size = 20;
  doubleSize = this.size * 2 + 1;

  updates = 0;

  constructor() {
    super('marienkaefer')
  }

  preload() {
    this.load.image(ImageNames.Ladybug, ladybug)
  }

  create() {
    super.create();

    let width: number = this.getW();
    let height: number = this.getH();
    let rows: number = this.doubleSize;
    let cols: number = this.doubleSize;
    this.makeGrid(rows, cols);
    this.grid.show();


    // this.blocks = this.add.group();

    // var g1 = this.add.grid(500, 500, 1000, 1000, 16, 16, 0x057605);
    // let block1 = this.add.rectangle(0, 0, 1, 1, 0x6666ff);
    // Align.scaleToGameW(block1, 1 / doubleSize, this);
    // this.grid.placeAt(6, 4, block1);

    this.maze = new MazeBuilder(this.size, this.size);
    console.log(this.maze.maze);
    this.maze.placeKey();
    this.maze.display("maze_container");
    let entrance, exit;
    this.maze.maze.forEach((row, rowIndex) => {
      row.forEach((cell, cellIndex) => {
        cell.forEach((type: string, typeIndex: number) => {
          switch (type) {
            case "wall":
              let wall = this.add.rectangle(0, 0, 1, 1, 0x6666ff);
              Align.scaleToGameW(wall, 1 / this.doubleSize, this);
              this.grid.placeAt(cellIndex, rowIndex, wall);
              break;
            case "key":
              this.key = this.add.rectangle(0, 0, 1, 1, 0x00ff00);
              Align.scaleToGameW(this.key, 1 / this.doubleSize / 2, this);
              this.grid.placeAt(cellIndex, rowIndex, this.key);
              this.keyCoords = new Point(cellIndex, rowIndex);
              break;
            // case "door":
            //   let door = this.add.rectangle(0, 0, 1, 1, 0x0000ff);
            //   Align.scaleToGameW(door, 1 / doubleSize / 2, this);
            //   this.grid.placeAt(cellIndex, rowIndex, door);
            //   break;
            case "entrance":
              entrance = this.add.rectangle(0, 0, 1, 1, 0xdddddd);
              Align.scaleToGameW(entrance, 1 / this.doubleSize / 2, this);
              this.grid.placeAt(cellIndex, rowIndex, entrance);

              this.ladybug = this.add.image(0, 0, ImageNames.Ladybug)
              Align.scaleToGameW(this.ladybug, 1 / this.doubleSize, this);
              this.grid.placeAt(cellIndex, rowIndex, this.ladybug);
              this.ladybugCoords = new Point(cellIndex, rowIndex);
              break;
            case "exit":
              exit = this.add.rectangle(0, 0, 1, 1, 0xff0000);
              Align.scaleToGameW(exit, 1 / this.doubleSize / 2, this);
              this.grid.placeAt(cellIndex, rowIndex, exit);
              break;
          }
        });
      });
    });
  }

  update() {
    if ((this.updates == 1 || this.updates == 2) && this.ladybugCoords && this.keyCoords) {
      // console.log('update');
      console.log(this.ladybugCoords, this.keyCoords);
      var route = AStar.findRoute(this.maze.maze, this.ladybugCoords, this.keyCoords);
      console.log(route);
      route.expanded.forEach((point: Node, index: number) => {
        let pathNode = this.add.rectangle(0, 0, 1, 1, 0x444444);
        Align.scaleToGameW(pathNode, 1 / this.doubleSize / 6, this);
        this.grid.placeAt(point.x, point.y, pathNode);
      });
      if (route.path && route.path.length) {
        route.path.forEach((point: Node, index: number) => {
          let pathNode = this.add.rectangle(0, 0, 1, 1, 0x4444ff);
          Align.scaleToGameW(pathNode, 1 / this.doubleSize / 4, this);
          this.grid.placeAt(point.x, point.y, pathNode);
        });
      }
    }

    this.updates++;
  }
}