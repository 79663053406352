import Point from "./Point";

export default class Node {
  x!: number;
  y!: number;
  parent?: Node;
  cost: number;
  distanceToTarget: number;
  combinedCost: number;

  constructor(point: Point, cost: number, distanceToTarget: number) {
    this.x = point.x;
    this.y = point.y;
    this.cost = cost;
    this.distanceToTarget = distanceToTarget;
    this.combinedCost = this.getCombinedCost();
  }

  private getCombinedCost() {
    return this.cost + this.distanceToTarget * 10;
  }
}